/* eslint-disable react/jsx-no-target-blank */
import React from "react"
import SEO from "../components/seo"
import useScript from "../hooks/use-script"
import { Link, withPrefix } from "gatsby"
import Layout from "../components/layout"
const DocumentSharingTracking = (): JSX.Element => {
  useScript(withPrefix("js/show-on-scroll.js"))
  useScript(withPrefix("js/sticky-header.js"))
  useScript(withPrefix("animations/documentsharing/documentsharing.js"))
  useScript(withPrefix("animations/documentsharing/documentsharinglotte.js"))

  const schema = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebPage",
        "@id": "https://documentesign.com/document-sharing/#webpage",
        url: "https://documentesign.com/document-sharing/",
        name: "Online Document Sharing and Tracking",
        isPartOf: {
          "@type": "WebSite",
          "@id": "https://documentesign.com/#website",
        },
        primaryImageOfPage: {
          "@id":
            "https://documentesign.com/static/bcc56467c53b14453fd65055583f769a/home-benefits-img.jpg#primaryimage",
        },
        datePublished: "2021-02-16T07:56:57+00:00",
        dateModified: "2021-04-28T16:44:56+00:00",
        description:
          "Sign documents with our free Document eSign Online signature solutions to close deals faster and accelerate your sales.",
        inLanguage: "en-US",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: ["https://documentesign.com/document-sharing/"],
          },
        ],
      },
    ],
  }

  return (
    <>
      <Layout>
        <SEO
          title="Online Document Sharing and Tracking | Document eSign "
          description=" Transform the way you share, manage, approve, store and secure  your important digital documents with document eSign"
          schemaMarkup={schema}
        />
        <div className="feature-details doc-sharing-tracking">
          <div className="intro show-on-scroll ani-in">
            <div className="container position-relative mb-md-n5">
              <div className="row">
                <div className="col-lg-10 offset-lg-1 text-center pt-lg-5">
                  <div className="h4 pt-5 pb-3">
                    DOCUMENT SHARING AND TRACKING
                  </div>
                  <h1 className="mb-lg-n4">
                    Online document tracking and <br />
                    sharing solutions
                  </h1>
                </div>
                <div className="col-md-6 offset-md-3 mt-md-n5 text-center">
                  <div
                    id="documentsharing_lottie_container"
                    className="feature-lottie-ani"
                  ></div>
                </div>
              </div>
            </div>
            <div className="bg-shape d-none d-lg-block">
              <svg
                width="2560px"
                height="100px"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                preserveAspectRatio="none"
                x="0px"
                y="0px"
                viewBox="0 0 2560 100"
                xmlSpace="preserve"
                enableBackground="new 0 0 2560 100"
              >
                <polygon points="2560 0 2560 100 0 100"></polygon>
              </svg>
            </div>
          </div>
          <div className="container mt-md-5 pt-md-5">
            <div className="row pt-md-4">
              <div className="col-md-6 offset-md-3 text-center show-on-scroll ani-in">
                <p className="text-light-100 pt-4 pt-md-0">
                  Track and share documents with Document esign and stay on top
                  of every process. Our digital esignature software allows you
                  to track documents to improve your workflow and get more deals
                  done. Share documents securely with Document eSign and
                  real-time insights of the details and status of any document
                  so that you don&apos;t have to manually track the document
                  progress unless you want to.
                </p>
                <a
                  href="https://app.documentesign.com/auth/signup"
                  target="_blank"
                  className="btn btn-dark px-md-5 mt-2 mb-2"
                >
                  Get started
                </a>
                <br />
                <small className="text-muted">
                  <strong>Free forever,</strong> No credit card required
                </small>
              </div>
            </div>
          </div>
          <div className="container py-5">
            <div className="card features-bx mb-5">
              <img
                src={withPrefix("images/st-top-particles.svg")}
                className="img-fluid show-on-scroll ani-in"
                alt="..."
              />

              <div className="card-body px-lg-5 mt-md-n5 py-0">
                <div className="row">
                  <div className="col-sm-6 mb-4 mb-sm-5 show-on-scroll ani-in">
                    <div className="feature-icon mb-3">
                      <i className="fas fa-cogs"></i>
                    </div>

                    <div className="h4">Automated document tracking</div>
                    <p className="text-light-100">
                      Document eSign document tracking solution enables you to
                      get instant and real time notifications of any activities
                      in your document.
                    </p>
                  </div>
                  <div className="col-sm-6 mb-4 mb-sm-5 show-on-scroll ani-in">
                    <div className="feature-icon mb-3">
                      <i className="fas fa-share-alt"></i>
                    </div>

                    <div className="h4">Share documents</div>
                    <p className="text-light-100">
                      Share your documents securely with our digital signature
                      online services to streamline business processes and seal
                      important deals a lot faster.
                    </p>
                  </div>
                  <div className="col-sm-6 mb-4 mb-sm-5 show-on-scroll ani-in">
                    <div className="feature-icon mb-3">
                      <i className="fas fa-file-alt"></i>
                    </div>

                    <div className="h4">Check status of your documents</div>
                    <p className="text-light-100">
                      Document esign esignature services provides you with
                      document analytics that show you a detailed step-by-step
                      history for each document.
                    </p>
                  </div>
                  <div className="col-sm-6 mb-4 mb-sm-5 show-on-scroll ani-in">
                    <div className="feature-icon mb-3">
                      <i className="fas fa-search"></i>
                    </div>

                    <div className="h4">Find your document</div>
                    <p className="text-light-100">
                      Document esign document tracking solution allows you to
                      index your lost documents and help you find the exact
                      document you’re looking for.
                    </p>
                  </div>
                </div>
              </div>
              <div className="feature-bx-cta text-center show-on-scroll ani-in">
                <Link to="/features/" className="btn btn-outline-dark">
                  Explore more features{" "}
                  <i className="fas fa-arrow-right pl-2"></i>
                </Link>
              </div>
              <img
                src={withPrefix("images/st-bottom-particles.svg")}
                className="img-fluid show-on-scroll ani-in"
                alt="..."
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default DocumentSharingTracking
